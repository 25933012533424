import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

import { Grid } from "../../ui/GridWrapper";
import Logo from "../Header/PalLogo.inline.svg";

const FooterWrapper = styled.footer`
  width: 100%;
  margin: auto;
  position: relative;
  padding: 5px 0;
  border-top: 1px solid ${(props) => props.theme.colors.color};
  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 5px 10px 0 10px;
  }
`;

const LogoWrapper = styled.div`
  grid-column: 1 / span 6;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0;
  svg {
    height: 70px;
    width: auto;
  }
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    margin: 0 0 0 -5px;
    border-left: 1px solid ${(props) => props.theme.colors.color};
  }
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    margin: 0 -5px 0 0;
    border-right: 1px solid ${(props) => props.theme.colors.color};
  }
  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 1 / span 3;
    grid-row: 1 / span 2;
    align-items: flex-start;
    svg {
      height: 28px;
    }
  }
`;

const LinkWrapper = styled.div`
  grid-column: span ${(props) => props.span};
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
  font-size: 20px;
  line-height: 1.1;
  padding: 5px 0;
  position: relative;
  &:nth-last-child(1) {
    &:after {
      content: "";
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      margin: 0 -5px 0 0;
      border-right: 1px solid ${(props) => props.theme.colors.color};
    }
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 4 / span 3;
    padding: 0;
    &:nth-last-child(-n + 3) {
      &:after {
        content: "";
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        margin: 0 -5px 0 0;
        border-right: 1px solid ${(props) => props.theme.colors.color};
      }
    }
    &:nth-last-child(1) {
      padding-bottom: 30px;
    }
  }
`;

const Spacer = styled.div`
  grid-column: span ${(props) => props.span};
  position: relative;
  padding: 5px 0;
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    margin: 0 -5px 0 0;
    border-right: 1px solid ${(props) => props.theme.colors.color};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    display: none;
  }
`;

function Footer() {
  const result = useStaticQuery(graphql`
    query Footer {
      allSanitySiteSettings {
        edges {
          node {
            _id
            _key
            title
            keywords
            tel
            email
            socialLinks {
              _key
              title
              url
            }
          }
        }
      }
    }
  `);
  const data = result.allSanitySiteSettings.edges[0].node;
  //console.log(data);
  return (
    <FooterWrapper>
      <Grid>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Spacer span={2} />
        <LinkWrapper span={2}>
          <a href={data.email} target="_blank" rel="noopener noreferrer">
            {data.email.replace("mailto:", "")}
          </a>
          <a href={data.tel} target="_blank" rel="noopener noreferrer">
            {data.tel.replace("tel:", "")}
          </a>
        </LinkWrapper>
        <Spacer span={1} />
        <LinkWrapper span={1}>
          {data.socialLinks.map((link, index) => {
            return (
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                {link.title}
              </a>
            );
          })}
        </LinkWrapper>
      </Grid>
    </FooterWrapper>
  );
}

export default Footer;
