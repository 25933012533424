import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const Grid = styled(motion.div)`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
 
   overflow-x: hidden;
  gap: 0 10px;
  /* Mobile grid */
  @media ${(props) => props?.theme?.breakpoints?.mobile} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const Wrapper = styled(motion.div)`
  grid-column: 1 / -1;
  width: 100%;
  padding: ${(props) => props.padding};
  ${({ border }) =>
    border &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.color};
    `};

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 10px 0;
  }
`;
