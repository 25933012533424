import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
// import {motion} from "framer-motion"

export const Links = styled((props) => <Link {...props} />)`
  grid-column: ${(props) => props.grid};
  text-decoration: none;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  color: ${(props) => props.theme.colors.color};
  transition: color 0.25s ease-in-out;
  &:hover {
    color: ${(props) => props.theme.colors.brand};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    /* Mobile grid */
    width: 100%;
  }
`;
