import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { AnimatePresence, motion, useScroll } from "framer-motion";

import { useUIContext } from "../../context/";
import PalLogo from "./PalLogo.inline.svg";
import MenuIcon from "./MenuIcon.inline.svg";
import { Grid } from "../../ui/GridWrapper";
import { Links } from "../../ui/Links";
import { defaultTransition } from "../../utils/transition";
import { useIsMobile } from "../../hooks/useMediaQuery";

const Head = styled(motion.header)`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  padding: 15px 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.color};
  overflow: hidden;
  background: ${(props) => props.theme.colors.bg};
  z-index: 999;

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 15px 10px;
  }
`;

const HeaderItems = styled(motion.div)`
  grid-column: 1/-1;
`;

const Logo = styled(motion.div)`
  grid-column-start: 1;
  width: 100%;
  height: 36.5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    width: 56px;
  }
`;
const Menu = styled.div`
  grid-column-end: -1;
  width: 100%;
  height: 36.5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    width: 26px;
    height: 26px;
    cursor: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23EB3329'/%3E%3C/svg%3E%0A")
        16 16,
      auto;
    transition: transform 0.25s ease-in-out;
    transform: ${(props) => (props.isOpen ? `rotate(45deg)` : `rotate(0)`)};
    transform-origin: center;
  }
`;

const MenuItems = styled(motion.div)`
  grid-column: 8 / span 4;
  font-family: ${(props) => props.theme.fonts.mono};
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0%;
  line-height: 1.26;
  /* display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  padding-top: 7.5px;
  margin-bottom: 29px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-row: 2;
    grid-column: 1/-1;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    width: 100%;
    margin-bottom: 0;
    padding-top: 15px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const Item = styled.ul`
  list-style: none;
  text-transform: uppercase;
  white-space: nowrap;
  /* min-width: 100%;
  max-width: 100%; */

  li:first-of-type {
    margin-top: 19px;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    li {
      display: none;
    }
  }
`;

export default function Header() {
  const isOpen = useUIContext((s) => s.isOpen);
  const setIsOpen = useUIContext((s) => s.setIsOpen);
  const { scrollY } = useScroll();
  const location =
    typeof window !== `undefined` ? window.location.pathname : null;
  const [isScrolled, setIsScrolled] = useState(location !== "/" ? true : false);
  const isMobile = useIsMobile();

  useEffect(() => {
    location !== "/" ? setIsScrolled(true) : setIsScrolled(false);
    scrollY.onChange((v) => {
      location !== "/" ? setIsScrolled(true) : setIsScrolled(v > 76);
    });
  }, [location, isOpen, scrollY]);

  const data = useStaticQuery(graphql`
    query Header {
      allSanityCategory {
        edges {
          node {
            _id
            title
            description
          }
        }
      }
    }
  `);

  return (
    <Head>
      <Grid>
        <HeaderItems>
          <Grid>
            <AnimatePresence mode="wait" initial={false}>
              {isMobile ? (
                <Links to="/">
                  <Logo
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: {
                        opacity: 1,
                        transition: { ...defaultTransition },
                      },
                      collapsed: {
                        opacity: 0,
                        transition: { ...defaultTransition },
                      },
                    }}
                  >
                    <PalLogo />
                  </Logo>
                </Links>
              ) : (
                isScrolled && (
                  <Links to="/">
                    <Logo
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: {
                          opacity: 1,
                          transition: { ...defaultTransition },
                        },
                        collapsed: {
                          opacity: 0,
                          transition: { ...defaultTransition },
                        },
                      }}
                    >
                      <PalLogo />
                    </Logo>
                  </Links>
                )
              )}
            </AnimatePresence>
            <AnimatePresence mode="wait">
              {isOpen && (
                <MenuItems
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: {
                      opacity: 1,
                      height: "auto",
                      transition: { ...defaultTransition },
                    },
                    collapsed: {
                      opacity: 0,
                      height: 0,
                      transition: { ...defaultTransition },
                    },
                  }}
                >
                  {/* <Item>
                    <Links to="/work">Work</Links>
                  </Item> */}
                  <Item>
                    <Links to="/expertise">Expertise</Links>
                    {/* {data.allSanityCategory.edges.map(({ node }) => {
                      return (
                        <li>
                          <Links
                            to={node.title
                              .toLowerCase()
                              .replaceAll(/\s+/g, "-")}
                          >
                            {node.title}
                          </Links>
                        </li>
                      );
                    })} */}
                  </Item>
                  <Item>
                    <Links to="/about">About</Links>
                  </Item>
                  <Item>
                    <a
                      href="mailto:hello@pal.tv"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get in touch
                    </a>
                  </Item>
                </MenuItems>
              )}
            </AnimatePresence>
            <Menu isOpen={isOpen}>
              <MenuIcon onClick={() => setIsOpen(!isOpen)} />
            </Menu>
          </Grid>
        </HeaderItems>
      </Grid>
    </Head>
  );
}
