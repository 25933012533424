import { createGlobalStyle } from "styled-components";

import ElastikB from "./fonts/Elastik-RegularB.woff";
import ElastikB2 from "./fonts/Elastik-RegularB.woff2";
import GTAmericaMonoRg from "./fonts/GTAmericaMono-Rg.woff";
import GTAmericaMonoRg2 from "./fonts/GTAmericaMono-Rg.woff2";

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        padding: 0;
        margin : 0;
        cursor: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23EB3329'/%3E%3C/svg%3E%0A") 12 12, auto!important;
    }

    @font-face {
    font-family: 'ElastikB';
    src: local('Elastik Regular B'), local('Elastik-RegularB'),
        url(${ElastikB}) format('woff'),
        url(${ElastikB2}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    }
    
    @font-face {
        font-family: 'GT America Mono';
        src: local('GT America Mono Regular'), local('GTAmericaMono-Regular'),
            url(${GTAmericaMonoRg}) format('woff'),
            url(${GTAmericaMonoRg2}) format('woff2');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    html, body {
        width: 100%;
        min-height: 1000px;
        height: auto;
        font-family: ${(props) => props.theme.fonts.main};
        background-color: ${(props) => props.theme.colors.bg};
        color: ${(props) => props.theme.colors.color};
        text-rendering: optimizelegibility;
        font-feature-settings: "kern";
    }

    .horizontal-scroll-container {
        pointer-events: none;
        grid-column: 1 / -1;
        /* & > div {
            height: 100%;
            & > div {
                height: 100%;
            }
        } */
    }

    ::selection {
        background: ${(props) => props.theme.colors.brand};
        color: ${(props) => props.theme.colors.bg};
    }

    #___gatsby, #gatsby-focus-wrapper {
        width: 100%;
        height: 100%;
    }

    a {
        text-decoration: none;
        color: inherit;
        transition: color 0.25s ease-in-out;
        &:hover {
            color: ${(props) => props.theme.colors.brand};
            /* cursor: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23EB3329'/%3E%3C/svg%3E%0A") 16 16, auto!important; */

        }
    }

    /* button {
        &:hover {
            cursor: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23EB3329'/%3E%3C/svg%3E%0A") 16 16, auto!important;
        }
    } */

    main {
        max-width: 1440px;
        margin: 5px auto;
        position: relative;
        & > div:first-of-type {
            &:before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                margin: 0 0 0 -5px;
                border-left: 1px solid ${(props) => props.theme.colors.color};
            }
            &:after {
                content: "";
                position: absolute;
                height: 100%;
                top: 0;
                right: 0;
                margin: 0 -5px 0 0;
                border-right: 1px solid ${(props) => props.theme.colors.color};
            }
        }
        width: calc(100% - 20px);
        @media ${(props) => props.theme.breakpoints.mobile} {
            margin: 10px auto;
        }
    }

    @supports (font-variation-settings: normal) {
    }

    /* Reset */
    
`;
