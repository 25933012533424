export const theme = {
  fonts: {
    main: "ElastikB, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
    mono: "GT America Mono, monospace",
  },
  colors: {
    bg: "#fff",
    color: "#000",
    brand: "#EB3329",
  },
  breakpoints: {
    desktop: "only screen and (min-width: 865px)",
    mobile: "only screen and (max-width: 480px)",
    tablet: "only screen and (max-width: 864px)",
  },
};
