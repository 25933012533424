/* eslint-disable react/no-unused-state */
import create from "zustand";
// import { persist } from "zustand/middleware";
// import { motionValue } from "framer-motion";

export const useUIContext = create((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),

  // 0 to 1 value (supported: x, y on mobile)
  //   scrollProgress: motionValue(0),
}));

export default useUIContext;
