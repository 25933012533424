import React from "react";

// import { Grid } from "../../ui/GridWrapper";
// import { GridOverlay } from "../../ui/GridOverlay";
import Header from "../Header";
import Footer from "../Footer";

export default function Layout({ children }) {
  return (
    <>
      {/* <Grid>
        <GridOverlay />
      </Grid> */}
      <Header />
      {children}
      <Footer />
    </>
  );
}
