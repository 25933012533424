import React from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "../../Theme";
import { GlobalStyle } from "../../ui/GlobalStyles";

export const ThemeContext = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};
