export const defaultDuration = 0.6;
export const defaultEase = [0.5, 0, 0, 1];

export const defaultTransition = {
  duration: 0.3,
  ease: [0, 0, 0.6, 1],
};

export const defaultSpring = {
  type: "spring",
  mass: 0.35,
  stiffness: 75,
  duration: 0.25,
};
